<!-- 普通页面顶部的那个标题部分 -->
<template>
  <!-- 头部 -->
  <div class="base-top-title">
    <div class="custom-title">{{ title }}</div>
    <div class="paragraph yy-container">
      <slot name="title"></slot>
    </div>
  </div>

  <!-- 路由页面 -->
  <div class="yy-container">
    <!-- 返回任务 -->
    <WaitHeader v-if="$route.meta.showWaitHeader"></WaitHeader>
    <!-- 页面 -->
    <router-view v-slot="{ Component }" :key="$route.fullPath">
      <Suspense>
        <keep-alive v-if="isKeepAlive">
          <component :is="Component" />
        </keep-alive>
        <component v-else :is="Component" />
        <!-- 加载区域 -->
        <template #fallback>
          <div class="flex items-center" style="height: 50vh">
            <CommonLoadingAnimeta height="5rem" width="5rem" fontSize="3rem" />
          </div>
        </template>
      </Suspense>
    </router-view>
  </div>
</template>

<script lang="ts" setup>
import WaitHeader from '@/views/Common/components/wait-header/index.vue'
import CommonLoadingAnimeta from '@/components/Loading/CommonLoadingAnimeta.vue'

withDefaults(
  defineProps<{
    title: string //标题
    isKeepAlive?: boolean //是否缓存路由页面
  }>(),
  {
    isKeepAlive: false,
  }
)
</script>

<script lang="ts">
export default {
  name: 'BaseMainComp',
}
</script>

<style scoped lang="scss">
.base-top-title {
  background-color: #74acdd;
  height: 200px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  flex-direction: column;
  .custom-title {
    font-size: 48px;
    font-weight: bold;
    color: #fff;
  }
  .paragraph {
    color: #ffffff;
    margin-top: 24px;
    margin-bottom: 20px;
  }
}
</style>
